
// 对比两个对象的值是否完全相等 返回值 true/false
export function isObjectValueEqual (a, b) {
    //取对象a和b的属性名
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    //判断属性名的length是否一致
    if (aProps.length != bProps.length) {
        return false;
    }
    //循环取出属性名，再判断属性值是否一致
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

//判断两个对象是否相等
export function isEqual(objA,objB){
    //相等
    if(objA === objB) return objA !== 0 || 1/objA === 1/objB;
    //空判断
    if(objA == null || objB == null) return objA === objB;
    //类型判断
    if(Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;

    switch(Object.prototype.toString.call(objA)){
        case '[object RegExp]':
        case '[object String]':
            //字符串转换比较
            return '' + objA ==='' + objB;
        case '[object Number]':
            //数字转换比较,判断是否为NaN
            if(+objA !== +objA){
                return +objB !== +objB;
            }

            return +objA === 0?1/ +objA === 1/objB : +objA === +objB;
        case '[object Date]':
        case '[object Boolean]':
            return +objA === +objB;
        case '[object Array]':
            //判断数组
            for(let i = 0; i < objA.length; i++){
                if (!isEqual(objA[i],objB[i])) return false;
            }
            return true;
        case '[object Object]':
            //判断对象
            let keys = Object.keys(objA);
            for(let i = 0; i < keys.length; i++){
                if (!isEqual(objA[keys[i]],objB[keys[i]])) return false;
            }

            keys = Object.keys(objB);
            for(let i = 0; i < keys.length; i++){
                if (!isEqual(objA[keys[i]],objB[keys[i]])) return false;
            }

            return true;
        default :
            return false;
    }
}


// 对比两个对象的值是否完全相等 返回不相等的属性
export function getObjectValueEqual (a, b) {
    //取对象a和b的属性名
    var aProps = Object.keys(a);
    var bProps = Object.keys(b);
    //判断属性名的length是否一致
    if (aProps.length != bProps.length) {
        return false;
    }
    //循环取出属性名，再判断属性值是否一致
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (a[propName] !== b[propName] && a[propName]!=='') {
            console.log(propName);
            if(b[propName]!==null){
                console.log("拼接");
                console.log(b[propName]);
                // console.log(a[propName]);
            }else{
                console.log("新增");
                console.log(a[propName])
            }
        }
    }
}
export function  formatDate(value, type) {
    let date = new Date(value);
    if(!value) {
        date = new Date();
    }
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();
    s = s < 10 ? ('0' + s) : s;
    if(type == 1) {
        return y + '-' + MM + '-' + d;
    } else if(type == 2) {
        return y + '年' + MM + '月' + d + '日';
    }else if(type == 3) {
        return y + '-' + MM + '-' + d + ' ' + '23:59:59'
    }  else {
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
}
export default {isObjectValueEqual,getObjectValueEqual,isEqual,formatDate}
