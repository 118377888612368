import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import moment from 'moment'
import echarts from 'echarts'
import ElementUI from 'element-ui';
import printJs from "print-js";
import jquery from 'jquery';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css' /*引入公共样式*/
import '@/assets/css/global.scss' /*引入公共样式*/
import '@/assets/iconfont/iconfont.css' /*图标样式*/
import './directive'
import Vue2Editor from "vue2-editor";
import '@/utils/filter'
import JeeplusFlow from 'jeeplus-flowable'
import 'jeeplus-flowable/lib/jeeplus-flowable.css'
import api from "@/utils/api"
import dictUtils from '@/utils/dictUtils'
import publicUtile from '@/utils/publicUtile'
import validator from '@/utils/validator'
import validate from '@/utils/validate'
import utils, {hasPermissionButton} from '@/utils'
import XEUtils from 'xe-utils'
import 'font-awesome/css/font-awesome.min.css'
import httpRequest2 from '@/utils/httpRequest2'
import common from '@/utils/common'
import {Base64} from 'js-base64'
import * as socketApi from '@/utils/socket'
import VueClipboards from 'vue-clipboard2'
import scroll from 'vue-seamless-scroll'
// 引入 Vue Awesome Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/src/swiper.scss'

import ElTableInfiniteScroll from "el-table-infinite-scroll";
// 懒加载
Vue.directive("el-table-infinite-scroll", ElTableInfiniteScroll);


import {
  // 核心
  VXETable,

  // 功能模块
  Icon,
  Filter,
  Menu,
  Edit,
  Export,
  Keyboard,
  Validator,
  Header,
  Footer,

  // 可选组件
  Column,
  Colgroup,
  Grid,
  Toolbar,
  Pager,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  RadioButton,
  Input,
  Textarea,
  Button,
  Modal,
  Tooltip,
  Form,
  FormItem,
  FormGather,
  Select,
  Optgroup,
  Option,
  Switch,
  List,
  Pulldown,

  // 表格
  Table
} from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'


// 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

// 表格功能
Vue.use(Header)
  .use(Footer)
  .use(Icon)
  .use(Filter)
  .use(Edit)
  .use(Menu)
  .use(Export)
  .use(Keyboard)
  .use(Validator)

  // 可选组件
  .use(Column)
  .use(Colgroup)
  .use(Grid)
  .use(Toolbar)
  .use(Pager)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Input)
  .use(Textarea)
  .use(Button)
  .use(Modal)
  .use(Tooltip)
  .use(Form)
  .use(FormItem)
  .use(FormGather)
  .use(Select)
  .use(Optgroup)
  .use(Option)
  .use(Switch)
  .use(List)
  .use(Pulldown)

  // 安装表格
  .use(Table)

Vue.use(ElementUI);
Vue.use(JeeplusFlow);
// Vue.use(JeeplusGencode)
// Vue.use(FormMaking);
Vue.use(Vue2Editor);
Vue.use(VueClipboards);
Vue.use(scroll)
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
// 挂载全局

//图片上传地址
import { request, globalUrl, uploadImgUrl, download, upLoadFile,pdfPreviewUrl } from './utils/httpRequest'
Vue.prototype.jquery = $
Vue.prototype.$upLoadFile = upLoadFile;
Vue.prototype.$globalUrl = globalUrl;
Vue.prototype.$pdfPreviewUrl = pdfPreviewUrl;
Vue.prototype.$http = httpRequest2 // ajax请求方法
Vue.prototype.$axios = request;  //ajax
Vue.prototype.$uploadImgUrl = uploadImgUrl;  //图片上传地址
Vue.prototype.$download = download;  //ajax
Vue.prototype.api = api;  //接口
Vue.prototype.common = common;  //日期方法
Vue.prototype.$utils = utils
Vue.prototype.hasPermission = utils.hasPermission // 权限方法
Vue.prototype.hasPermissionButton = utils.hasPermissionButton // 按钮权限方法
Vue.prototype.getResourceUploadFileFormat = utils.getResourceUploadFileFormat // 资源采集上传文件格式
Vue.prototype.getUploadNotCurrentlySupported = utils.getUploadNotCurrentlySupported // 资源采集上传暂不支持文件格式
Vue.prototype.getResourcePreviewFileFormat = utils.getResourcePreviewFileFormat // 资源采集支持预览的文件格式
Vue.prototype.metadataDateFormatValue = utils.metadataDateFormatValue // 元数据时间格式化
Vue.prototype.getFileIcon = utils.getFileIcon // 分件图标
Vue.prototype.QueryString = utils.QueryString // 获取页面链接参数
Vue.prototype.formatDate = utils.formatDate // 时间格式化
Vue.prototype.changeMainColor = utils.changeMainColor // 切换主题色
Vue.prototype.validator = validator
Vue.prototype.validate = validate
Vue.prototype.recover = utils.recover // 权限方法
Vue.prototype.exportExcel = utils.exportExcel // 导出
Vue.prototype.$dictUtils = dictUtils
Vue.prototype.$publicUtile = publicUtile
Vue.prototype.$echarts = echarts
Vue.prototype.moment = moment
Vue.prototype.$Base64 = Base64;
Vue.prototype.$print = printJs
Vue.prototype.$events = new Vue()
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@/utils/flexible.js'
import {exportExcel, formatDate, getFileUploadFormat, getResourcePreviewFileFormat} from "./utils";

Vue.use(Viewer)
Vue.prototype.$utils = {
  parseDate(str) {
    if (typeof str == 'string') {
      var results = str.match(/^ *(\d{4})-(\d{1,2})-(\d{1,2}) *$/);
      if (results && results.length > 3)
        return new Date(parseInt(results[1]), parseInt(results[2]) - 1, parseInt(results[3]));
      results = str.match(/^ *(\d{4})-(\d{1,2})-(\d{1,2}) +(\d{1,2}):(\d{1,2}):(\d{1,2}) *$/);
      if (results && results.length > 6)
        return new Date(parseInt(results[1]), parseInt(results[2]) - 1, parseInt(results[3]), parseInt(results[4]), parseInt(results[5]), parseInt(results[6]));
      results = str.match(/^ *(\d{4})-(\d{1,2})-(\d{1,2}) +(\d{1,2}):(\d{1,2}):(\d{1,2})\.(\d{1,9}) *$/);
      if (results && results.length > 7)
        return new Date(parseInt(results[1]), parseInt(results[2]) - 1, parseInt(results[3]), parseInt(results[4]), parseInt(results[5]), parseInt(results[6]), parseInt(results[7]));
    }
    return null;
  },
  clone: function (obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  getSize: function (size) {
    if (size < 1024) {
      return "1KB"
    }
    if (size > 1024 && size < 1024 * 1024) {
      return parseInt(size / 1024) + "KB"
    }
    if (size > 1024 * 1024 && size < 1024 * 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + "MB"
    }
  },
  getSuffix: function (filename) {
    var index = filename.lastIndexOf('.');
    if (index >= 0) {
      return filename.substring(index + 1, filename.length).toLowerCase();
    } else {
      return null;
    }
  },
  getIcon: function (filename) {
    var suffix = this.getSuffix(filename);
    if ('pdf' === suffix) {
      return 'icon-pdf';
    } else
      if ("doc" === suffix || 'docx' === suffix) {
        return 'icon-wordwenjian';
      } else
        if ('pptx' === suffix || 'ppt' === suffix) {
          return 'icon-ppt';
        } else
          if ('xls' === suffix || 'xlsx' === suffix) {
            return 'icon-excelwenjian';
          } else {
            return 'icon-Document';
          }
  },
  contains(arr, obj) {
    var i = arr.length;
    while (i--) {
      if (arr[i] === obj) {
        return true;
      }
    }
    return false;
  }
}
// Vue.prototype.GLOBAL = global//挂载到Vue实例上面
Vue.prototype.socketApi = socketApi
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
