import validate from './validate'

var isMobile = (rule, value, callback) => {
  if (value && !validate.isMobile(value)) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}

var isMobileCode = (rule, value, callback) => {
  if (value && !validate.isMobileCode(value)) {
    callback(new Error('请输入正确的验证码'))
  } else {
    callback()
  }
}

var isPhone = (rule, value, callback) => {
  if (value && !validate.isPhone(value)) {
    callback(new Error('请输入正确的电话'))
  } else {
    callback()
  }
}

var isPwd = (rule, value, callback) => {
  if (value && !validate.isPwd(value)) {
    callback(new Error('密码由8~16位数字、特殊字符、大写字母、小写字母组合'))
  } else {
    callback()
  }
}

var isEmail = (rule, value, callback) => {
  if (value && !validate.isEmail(value)) {
    callback(new Error('请输入有效的邮箱'))
  } else {
    callback()
  }
}

var isURL = (rule, value, callback) => {
  if (value && !validate.isURL(value)) {
    callback(new Error('请输入有效的URL'))
  } else {
    callback()
  }
}

var isDigits = (rule, value, callback) => {
  if (value && !validate.isDigits(value)) {
    callback(new Error('只能输入数字'))
  } else {
    callback()
  }
}

var isChinese = (rule, value, callback) => {
  if (value && !validate.isChinese(value)) {
    callback(new Error('只能输入中文'))
  } else {
    callback()
  }
}

var isPrice = (rule, value, callback) => {
  if (value && !validate.isPrice(value)) {
    callback(new Error('请输入正确的价格'))
  } else {
    callback()
  }
}

var isEmoji = (rule, value, callback) => {
  if (value && validate.isEmoji(value)) {
    callback(new Error('请勿输入特殊字符'))
  } else {
    callback()
  }
}

var numLimit = (rule, value, callback) => {
  if (value && !validate.numLimit(value)) {
    callback(new Error('请输入正确的数量'))
  } else {
    callback()
  }
}


export default {numLimit, isEmoji, isPrice, isURL, isEmail, isMobile, isPhone, isPwd, isMobileCode, isDigits, isChinese}
