/**
 * 全站路由配置
 * 代码中路由统一使用path属性跳转
 */
import Vue from 'vue'
import Router from 'vue-router'
import {request} from '@/utils/httpRequest'
import api from '@/utils/api'
import {isURL} from '@/utils/validate'
import {refreshDictList} from '@/utils/dictUtils'

Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
// 开发环境不使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)
// 全局路由
const globalRoutes = [
    {path: '/login', component: _import('modules/sys/login/login'), name: 'login', meta: {title: '登录'}},
    {path: '/menuHome', component: _import('menuHome'), name: 'menuHome', meta: {title: '首页'}},
    {path: '/collBoard', component: _import('modules/workbench/bulletinBoard/collBoard'), name: 'collBoard', meta: {title: '典藏数据综合看板'}},
    {path: '/collectionOverview', component: _import('modules/workbench/bulletinBoard/collectionOverview'), name: 'collectionOverview', meta: {title: '雷锋纪念馆数字藏品总览'}},
]

// 主入口路由
const mainRoutes = {
    path: '/',
    component: _import('main'),
    name: 'main',
    redirect: {name: 'login'},
    meta: {title: '整体布局'},
    children: [
        {
            path: '/dyyg/home/retrievedResult',
            component: _import('modules/dyyg/home/retrievedResult'),
            name: 'retrievedResult',
            meta: {title: '检索结果'}
        },
        {
            path: '/dyyg/home/retrievedDetails',
            component: _import('modules/dyyg/home/retrievedDetails'),
            name: 'retrievedDetails',
            meta: {title: '资源详情'}
        },
        {
            path: '/collection/accounts/addCollection',
            component: _import('modules/collection/accounts/addCollection'),
            name: 'addCollection',
            meta: {title: '藏品详情'}
        },
        {
            path: '/collection/collect/addRegistration',
            component: _import('modules/collection/collect/addRegistration'),
            name: 'addRegistration',
            meta: {title: '征集登记详情'}
        },
        {
            path: '/collection/accounts/ledgerList',
            component: _import('modules/collection/accounts/ledgerList'),
            name: 'ledgerList',
            meta: {title: '分类账列表'}
        },
        {
            path: '/record/query/databaseResult',
            component: _import('modules/record/query/databaseResult'),
            name: 'databaseResult',
            meta: {title: '数据库检索结果'}
        },
        {
            path: '/record/borrow/electronic/auditorList',
            component: _import('modules/record/borrow/electronic/auditorList'),
            name: 'electronicAuditorList',
            meta: {title: '【电子档案借阅】我的审核'}
        },
        {
            path: '/record/borrow/entity/lend/auditorList',
            component: _import('modules/record/borrow/entity/lend/auditorList'),
            name: 'entityLendAuditorList',
            meta: {title: '【借出利用】我的审核'}
        },
        {
            path: '/record/borrow/entity/lend/operatorList',
            component: _import('modules/record/borrow/entity/lend/operatorList'),
            name: 'entityLendOperatorList',
            meta: {title: '【借出利用】我的经办'}
        },
        {
            path: '/record/borrow/entity/interior/auditorList',
            component: _import('modules/record/borrow/entity/interior/auditorList'),
            name: 'entityInteriorAuditorList',
            meta: {title: '【内部利用】我的审核'}
        },
        {
            path: '/record/borrow/entity/interior/operatorList',
            component: _import('modules/record/borrow/entity/interior/operatorList'),
            name: 'entityInteriorOperatorList',
            meta: {title: '【内部利用】我的经办'}
        },
        {
            path: '/record/query/fulltextResult',
            component: _import('modules/record/query/fulltextResult'),
            name: 'fulltextResult',
            meta: {title: '全文检索结果'}
        },
        {
            path: '/collection/business/disinfectDetail',
            component: _import('modules/collection/business/disinfectDetail'),
            name: 'disinfectDetail',
            meta: {title: '新增消毒'}
        },
        {
            path: '/collection/business/collectionDet',
            component: _import('modules/collection/business/collectionDet'),
            name: 'collectionDet',
            meta: {title: '藏品注销详情'}
        },
        {
            path: '/collection/immovable/addMovable',
            component: _import('modules/collection/immovable/addMovable'),
            name: 'addMovable',
            meta: {title: '新增不可移动文物'}
        },
        {
            path: '/collection/business/exhibitionDetail',
            component: _import('modules/collection/business/exhibitionDetail'),
            name: 'exhibitionDetail',
            meta: {title: '展览新增'}
        },
        {
            path: '/collection/business/expertDatabase/addAppraisal',
            component: _import('modules/collection/business/expertDatabase/addAppraisal'),
            name: 'addAppraisal',
            meta: {title: '藏品鉴定专家新增'}
        },
        {
            path: '/collection/business/collIdentification/addIdentify',
            component: _import('modules/collection/business/collIdentification/addIdentify'),
            name: 'addIdentify',
            meta: {title: '藏品鉴定登记详情'}
        },
        {
            path: '/collection/business/collIdentification/inventoryDetail',
            component: _import('modules/collection/business/collIdentification/inventoryDetail'),
            name: 'inventoryDetail',
            meta: {title: '藏品鉴定登记详情'}
        },
        {
            path: '/collection/business/LiteratureManage/literatureDetail',
            component: _import('modules/collection/business/LiteratureManage/literatureDetail'),
            name: 'literatureDetail',
            meta: {title: '文献详情'}
        },
        {
            path: '/collection/business/repair/addRepair',
            component: _import('modules/collection/business/repair/addRepair'),
            name: 'addRepair',
            meta: {title: '修复登记详情'}
        },
        {
            path: '/collection/business/repair/addRepair',
            component: _import('modules/collection/business/repair/addRepair'),
            name: 'addRepair',
            meta: {title: '修复登记详情'}
        },
        {
            path: '/collection/business/repair/recordDetail',
            component: _import('modules/collection/business/repair/recordDetail'),
            name: 'recordDetail',
            meta: {title: '修复记录详情'}
        },
        {
            path: '/collection/business/fault/addFault',
            component: _import('modules/collection/business/fault/addFault'),
            name: 'addFault',
            meta: {title: '事故登记详情'}
        },
        {
            path: '/collection/business/fault/recordDetail',
            component: _import('modules/collection/business/fault/recordDetail'),
            name: 'recordDetail',
            meta: {title: '事故记录详情'}
        },
        {
            path: '/collection/business/products/addProducts',
            component: _import('modules/collection/business/products/addProducts'),
            name: 'addProducts',
            meta: {title: '复仿制品详情'}
        },
        {
            path: '/collection/business/mount/addMount',
            component: _import('modules/collection/business/mount/addMount'),
            name: 'addMount',
            meta: {title: '藏品装裱详情'}
        },
        {
            path: '/collection/business/mount/recordDetail',
            component: _import('modules/collection/business/mount/recordDetail'),
            name: 'recordDetail',
            meta: {title: '藏品装裱记录详情'}
        },
        {
            path: '/collection/business/protect/addProtect',
            component: _import('modules/collection/business/protect/addProtect'),
            name: 'addProtect',
            meta: {title: '保护情况详情'}
        },
        {
            path: '/collection/business/protect/recordDetail',
            component: _import('modules/collection/business/protect/recordDetail'),
            name: 'recordDetail',
            meta: {title: '保护情况记录详情'}
        },
        {
            path: '/collection/business/outBound/addOutbound',
            component: _import('modules/collection/business/outBound/addOutbound'),
            name: 'addOutbound',
            meta: {title: '我申请的详情'}
        },
        {
            path: '/collection/business/outBound/outboundDetails',
            component: _import('modules/collection/business/outBound/outboundDetails'),
            name: 'outboundDetails',
            meta: {title: '出库详情'}
        },
        {
            path: '/collection/business/warehousing/wareDetail',
            component: _import('modules/collection/business/warehousing/wareDetail'),
            name: 'wareDetail',
            meta: {title: '入库详情'}
        },
        {
            path: '/collection/business/warehousing/ruboundDetails',
            component: _import('modules/collection/business/warehousing/ruboundDetails'),
            name: 'ruboundDetails',
            meta: {title: '入库详情'}
        },
        {
            path: '/collection/business/warehousing/shenDetail',
            component: _import('modules/collection/business/warehousing/shenDetail'),
            name: 'shenDetail',
            meta: {title: '入库审批详情'}
        },
        {
            path: '/workbench/dataRetrieval',
            component: _import('modules/workbench/dataRetrieval'),
            name: 'dataRetrieval',
            meta: {title: '数据检索'}
        },
        {
            path: '/dyyg/imageProcessing/label',
            component: _import('modules/dyyg/imageProcessing/label'),
            name: 'label',
            meta: {title: '图片手动标注'}
        },
        {
            path: '/dyyg/comprehensive/searchResult',
            component: _import('modules/dyyg/comprehensive/searchResult'),
            name: 'searchResult',
            meta: {title: '搜索结果'}
        },
        {path: '/404', component: _import('common/404'), name: '404', meta: {title: '404未找到'}},

    ]
}

const router = new Router({
    mode: 'history',  //去掉url中的#
    // mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
    routes: globalRoutes.concat(mainRoutes)
})

// 添加动态(菜单)路由
router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem("token")
    if (!token || !/\S/.test(token)) { // token为空，跳转到login登录
        if (process.env.VUE_APP_SSO_LOGIN === 'true') { // 如果是单点登录
            if (to.name === 'casLogin') { // 单点登录跳转页面获取token
                next()
            } else {
                window.location.href = `${process.env.VUE_APP_CAS_SERVER}/login?service=${process.env.VUE_APP_CLIENT_LOGIN}`
            }
        } else {
            if (fnCurrentRouteType(to, globalRoutes) === 'global') {
                next()
            } else {
                next({name: 'login'})
            }
        }
    } else if (router.options.isAddDynamicMenuRoutes) { // 如果已经包含权限
        next()
    } else {
        request(api.auth.getMenus, {}, 'get').then(data => {
            if (data && data.status) {
                fnAddDynamicMenuRoutes(data.data.menuList)
                router.options.isAddDynamicMenuRoutes = true
                sessionStorage.setItem('allMenuList', JSON.stringify(data.data.menuList || []))
                sessionStorage.setItem('permissions', JSON.stringify(data.data.permissions || []))
                let dictList = JSON.parse(sessionStorage.getItem('dictList') || '[]')
                if (dictList.length == 0) {
                    refreshDictList();
                }
                next({...to, replace: true})
            } else {
                sessionStorage.setItem('allMenuList', JSON.stringify([]))
                sessionStorage.setItem('permissions', JSON.stringify([]))
                next()
            }
        }).catch((e) => {
            sessionStorage.clear();//清除缓存
            router.push({path: '/'});//返回登录页
        })
    }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
    let temp = []
    for (let i = 0; i < globalRoutes.length; i++) {
        if (route.path === globalRoutes[i].path) {
            return 'global'
        } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
            temp = temp.concat(globalRoutes[i].children)
        }
    }
    return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
    let temp = []
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].children && menuList[i].children.length >= 1) {
            temp = temp.concat(menuList[i].children)
        }

        if (menuList[i].href && /\S/.test(menuList[i].href)) {
            menuList[i].href = menuList[i].href.replace(/[/]$/, '')
            const route = {
                path: menuList[i].href.split('?')[0],
                component: null,
                name: menuList[i].href.replace(/^\//g, '').replace(/[/]/g, '-').replace(/[?]/g, '-').replace(/&/g, '-').replace(/=/g, '-'),
                meta: {
                    parentIds: menuList[i].parentIds,
                    menuId: menuList[i].id,
                    title: menuList[i].name,
                    isDynamic: true,
                    type: menuList[i].target,
                    affix: menuList[i].affix === '1',
                    iframeUrl: ''
                }
            }
            // url以http[s]://开头, 通过iframe展示
            if (isURL(menuList[i].href)) {
                route.path = '/' + route.path
                route.meta.iframeUrl = menuList[i].href
                route['meta']['type'] = 'iframe'
            } else if (menuList[i].target === 'iframe') {
                route['meta']['iframeUrl'] = `${process.env.VUE_APP_SERVER_URL}${menuList[i].href}`
            } else {
                try {
                    if (menuList[i].href) {
                        route['component'] = _import(`modules${menuList[i].href.split('?')[0]}`) || null
                    }
                } catch (e) {
                }
            }
            routes.push(route)
        }
    }
    if (temp.length >= 1) {
        fnAddDynamicMenuRoutes(temp, routes)
    } else {
        mainRoutes.name = 'main-dynamic'
        mainRoutes.children = routes
        router.addRoute(mainRoutes)
        router.addRoute({path: '*', redirect: {name: 'menuHome'}})
        sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || []))
    }
}

export default router
