<template>
  <router-view v-if="isRouterAlive"></router-view>
</template>

<script>
  export default {
    data() {
      return {
        isRouterAlive: true
      }
    },
    watch: {
      // 监听路由
      $route: {
        handler(val) {
          if(val.name == 'login') {
            let state = sessionStorage.getItem('loginState')
            if(!state) {
              window.location.reload();
              sessionStorage.setItem('loginState', '1')
            }
          }
        },
        deep:true,
        immediate: true
      },
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      }
    },
  }
</script>
<style scoped>
</style>
